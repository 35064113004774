import React from "react";
import YouTube from "react-youtube";

function WorkElement({ vidId, title, para }) {
  return (
    <div className="work">
      <div className="work__video--wrapper">
        <div className="work--showcase">
          <YouTube videoId={vidId}></YouTube>
        </div>
      </div>
      <div className="work--info">
        <h1 className="work--title">{title}</h1>
        <p className="work--para para">{para}</p>
      </div>
    </div>
  );
}

export default WorkElement;
