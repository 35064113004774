import React from "react";
import { Link } from "react-router-dom";

function Nav() {
  return (
    <nav>
      <div className="nav--container">
        <div className="nav__links">
          <Link to={"/"}>Home</Link>
          <Link to={"/work"}>Work</Link>
          <Link to={"/contact"}>Contact</Link>
        </div>
      </div>
    </nav>
  );
}

export default Nav;
