import React from "react";
import { BsTwitterX, BsDiscord, BsEnvelopeFill } from "react-icons/bs";

function Contact() {
  return (
    <main>
      <div className="container">
        <div className="row">
          <h1 className="title">Get in touch with me</h1>
          <a
            href="https://twitter.com/akselglyholt"
            rel="noopener noreferrer"
            target="_blank"
            className="contact__link"
          >
            <BsTwitterX />
            <span className="contact__link--text">akselglyholt</span>
          </a>
          <a
            href="https://discord.com"
            rel="noopener noreferrer"
            target="_blank"
            className="contact__link"
          >
            <BsDiscord />
            <span className="contact__link--text">Poloqize</span>
          </a>
          <a
            href="mailto:me@akselglyholt.com"
            rel="noopener noreferrer"
            target="_blank"
            className="contact__link"
          >
            <BsEnvelopeFill />
            <span className="contact__link--text">me@akselglyholt.com</span>
          </a>
        </div>
      </div>
    </main>
  );
}

export default Contact;
