import React from "react";
import WorkElement from "../Components/UI/Work/WorkElement";

function Work() {
  return (
    <main>
      <div className="container">
        <div className="row work--row">
          <h1 className="title">Work</h1>
          <div className="section-line"></div>
          <p className="para">Check out all of my work here</p>
          <div className="work--container">
            <WorkElement
              vidId={"g1qt6sL9CG4"}
              title="Flight Physics"
              para="Semi-advanced flight physics in Roblox, calculating drag, wind speed
          and density"
            />
            <WorkElement
              vidId={"zD481UWgPyo"}
              title="NPC system"
              para="NPC system. NPCs can spot the player and use advanced AI Pathfinding"
            />
          </div>
        </div>
      </div>
    </main>
  );
}

export default Work;
