import React from "react";
import Work from "../Components/Home/Work";

function Home() {
  return (
    <main id="landing">
      <div className="container landing--container">
        <div className="row landing--row">
          <header>
            <h1 className="title landing--title">Hi there!</h1>
            <h1 className="title landing--title landing--title-2 primcolor">
              I'm Aksel
            </h1>
            <p className="para landing--para">
              I'm a <b className="primcolor">Software Engineer</b> with a strong
              passion for building <b className="primcolor">Roblox games</b>{" "}
              with <b className="primcolor">functionality</b> and advanced game{" "}
              mechanics.
            </p>
            <a href="#work" className="scroll">
              <div className="scroll__icon"></div>
            </a>
          </header>
        </div>
      </div>
      <Work />
    </main>
  );
}

export default Home;
