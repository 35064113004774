import React from "react";
import YouTube from "react-youtube";

class WorkElement extends React.Component {
  render() {
    const { title, videoId, para } = this.props;

    return (
      <div className="home__work--container">
        <div className="home__work--video-container">
          <YouTube videoId={videoId} onReady={this._onReady}></YouTube>
        </div>
        <h1 className="home__work--title">{title}</h1>
        <p className="home__work--para">
          {para}
        </p>
      </div>
    );
  }

  _onReady(event) {
    event.target.pauseVideo();
  }
}

export default WorkElement;
