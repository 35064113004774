import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import WorkElement from "../UI/Home/WorkElement";

function Work() {
  const settings = {
    className: "center",
    centerMode: true,
    infinite: false,
    centerPadding: "60px",
    slidesToShow: 1,
    speed: 500,
    dots: true,
  };

  return (
    <div className="container" id="work">
      <div className="row work--row">
        <h1 className="title work__title">Showcased <b className="primcolor">Work</b></h1>
        <div className="section-line"></div>
        <div className="home__work--slider-container">
          <Slider {...settings}>
            <WorkElement
              title={"Flight Physics"}
              videoId={"g1qt6sL9CG4"}
              para="Simple Flight Physics, with wind, drag and air density calculation"
            ></WorkElement>
            <WorkElement
              title={"NPC Detection System"}
              videoId={"zD481UWgPyo"}
              para="NPC system. NPCs can spot the player and use advanced AI Pathfinding"
            ></WorkElement>

          </Slider>
        </div>
      </div>
    </div>
  );
}

export default Work;
